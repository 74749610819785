<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <company-submenu menu="subscribe"></company-submenu>

      <div class="submenu-content">
          <h2>Email Newsletters</h2>
          <p>To unsubscribe from our newsletters, submit the email address.</p>
          
          <div v-if="message" class="alert alert-success">{{message}}</div>
          <div v-if="error" class="alert alert-danger">{{error}}</div>

          <form class="row g-3 " @submit.prevent="handleSubmit">
            <div class="col-md-10" :class="{'alert alert-danger': data.email.error}" >
                <label class="form-label">Email <span class="text-danger">*</span></label>
                <div v-if="data.email.error">This field is required</div>
                <input type="email" class="form-control" v-model="data.email.input">
            </div>

            <div class="col-md-10">
                <button v-if="waiting" class="btn btn-primary btn-lg" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Submit
                </button>
                <button v-else type="submit" class="btn btn-primary btn-lg">Submit</button>
            </div>
          </form>
      </div>
  </div>

  <div id="google_recaptcha"></div>
</template>

<script>
import CompanySubmenu from '../../components/nav/CompanySubmenu.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { ref, onMounted } from 'vue'
import { insertReCaptchaScript, handleReCaptcha } from '../../utils/common'
import { getAPI } from '../../utils/axios-api'

export default {
  name: 'Unsubscribe',
  components: { CompanySubmenu, BreadcrumbPage },
  setup() {
    const waiting = ref(false)
    const message = ref(null)
    const error = ref(null)
    const data = ref({
                email: {input: null, error: false}
                })
    
    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Company", link: ""})
    breadcrumb_links.value.push({text: "Unsubscribe", link: "Unsubscribe"})

    //add script for google reCaptcha to template
    onMounted(() => {
        insertReCaptchaScript("google_recaptcha")
    })

    function validate () {
        let valid = true

        if (!!data.value.email.input) data.value.email.error = false
        else {
            valid = false        
            data.value.email.error = true
        }

        return valid
    }
    
    //submit form
    const handleSubmit = async () => {
      waiting.value =  true
      error.value = ""
      message.value = ""

      let result = await handleReCaptcha(grecaptcha)
      if (result["is_human"] == true) {
        //validate the form
        if (validate() == false) {
          waiting.value = false
          return 0
        }
        
        let payload = {
          first_name: "",
          last_name: "",
          email: data.value.email.input,
          company: "",
          branch: "",
          title: "",
          customer: "",
          custno: "",
          city: "",
          state: "",
          zip: "",
          country: "",
          phone: "",
          industry: "",
        }
        
        getAPI.post('/account/email-newsletters/no/', payload)
        .then((response) => {
          let res = response.data

          if (res["done"] == true) message.value = res["message"]
          else error.value = res["message"]
          
          data.value.email.input = ""

          waiting.value = false
        })
        .catch(err => {
          try {
            error.value = err.message
          }
          catch {
            error.value = "Something is wrong. Failed to unsubscribe the email."
          }
          waiting.value = false
        })
      }
      else {
        //if not pass google reCaptcha, show the error
        waiting.value = false
        error.value = result["message"]
      }
    
    }
    return {waiting,
            message,
            error,
            data,
            handleSubmit,
            breadcrumb_links}
  }
}
</script>

<style>

</style>